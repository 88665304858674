import React from 'react';
import LayoutComponent from "../components/layout.component"
import { Header } from "../components/navbar.component"
import { graphql } from "gatsby"
import { linkFragment, linkResolver } from "../link-resolver"
import { isDark } from "../utils/styles"
import { RichText } from "prismic-reactjs";
import { Slices } from "../components/slices.component"
import { Section } from "../components/common/section.component"


const ContentPage = ({uri, data}) => {
    const page = data.prismic.allContentPages.edges.find(item => {
        return uri === linkResolver(item.node._meta);
    })
    if (page) {
        const dark = isDark(page.node.background_color, page.node.background_image);
        return (
          <LayoutComponent>
              <Section backgroundImage={page.node.background_image} backgroundColor={page.node.background_color}>
                  <Header theme={dark ? 'dark' : 'light'}/>
                  <div className="py-5 text-center">
                      <RichText render={page.node.title} linkResolver={linkResolver} />
                  </div>
                <div className="content-page-text">
                    <RichText render={page.node.text} linkResolver={linkResolver} />
                </div>
              </Section>
            <Slices body={page.node.body} />
          </LayoutComponent>
        )
    }
    return null;
}

ContentPage.fragments = [linkFragment]

export default ContentPage;

export const query = graphql`
    query contentPageQuery {
        prismic {
            allContentPages {
                edges {
                    node {
                        _meta {
                            uid
                            type
                            lang
                        }
                        background_image
                        background_color
                        text
                        title
                        parent {
                            ... on PRISMIC_ContentPage {
                                _meta {
                                    lang
                                    type
                                    uid
                                }
                                parent {
                                    ... on PRISMIC_ContentPage {
                                        _meta {
                                            type
                                            uid
                                            lang
                                        }
                                        parent {
                                            ... on PRISMIC_ContentPage {
                                                _meta {
                                                    type
                                                    uid
                                                    lang
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        body {
                            ... on PRISMIC_ContentPageBodyItems_collection {
                                label
                                type
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                    title
                                    link_style
                                    link_text
                                }
                                fields {
                                    tag
                                }
                            }
                            ... on PRISMIC_ContentPageBodyText {
                                type
                                label
                                primary {
                                    text
                                    bg_color
                                    bg_image
                                }
                            }
                            ... on PRISMIC_ContentPageBodyFeature {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    text
                                }
                                fields {
                                    image
                                    link_style
                                    link_text
                                    text
                                    title
                                    link {
                                       ...link
                                    }
                                }
                            }
                            ... on PRISMIC_ContentPageBodyBlock_with_text_and_image {
                                label
                                type
                                primary  {
                                    bg_color
                                    bg_image
                                    min_height
                                    title
                                    text
                                    image
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                            ... on PRISMIC_ContentPageBodyForm {
                                type
                                label
                                primary {
                                    bg_color
                                    bg_image
                                    form_script
                                    form_url
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }
    }

`
